import { createGlobalStyle } from 'styled-components'

import isLightMode from 'helpers/isLightMode'

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Dm Sans';
    font-style: normal;
    font-weight: 400;
    src: url('/static/fonts/DmSans-Regular') format('ttf');
    font-display: swap;
  }
  @font-face {
    font-family: 'Dm Sans';
    font-style: normal;
    font-weight: 500;
    src: url('/static/fonts/DmSans-Medium') format('ttf');
    font-display: swap;
  }
  @font-face {
    font-family: 'Dm Sans';
    font-style: normal;
    font-weight: 700;
    src: url('/static/fonts/DmSans-Bold') format('ttf');
    font-display: swap;
  }
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('/static/fonts/OpenSans-Light.woff2') format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('/static/fonts/OpenSans-Regular.woff2') format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('/static/fonts/OpenSans-SemiBold.woff2') format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('/static/fonts/OpenSans-Bold.woff2') format('woff2');
    font-display: swap;
  }
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    src: url('/static/fonts/OpenSans-ExtraBold.woff2') format('woff2');
    font-display: swap;
  }
  body,
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html {
    box-sizing: border-box;
    line-height: 1.15;
    -ms-overflow-style: scrollbar;
    -webkit-text-size-adjust: 100%;
    height: -webkit-fill-available;
  }
  body {
    margin: 0;
    overflow-x: hidden;
    font-weight: normal;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    background-color: ${props =>
      isLightMode(props.application)
        ? props?.theme?.palette?.grayDark
        : props?.theme?.palette?.black};
    min-height: 100vh;
    min-height: -webkit-fill-available;


    @media print {
      background-color: transparent !important;
    }
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
    -webkit-tap-highlight-color: transparent;
  }
  button,
  input,
  select,
  textarea {
    margin: 0;
    font-size: 100%;
    font-family: inherit;
  }
  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    font-family: inherit;
    cursor: pointer;
    -webkit-appearance: button;

    &::-moz-focus-inner {
      padding: 0;
      border-style: none;
    }

    &:-moz-focusring {
      outline: 1px dotted ButtonText;
    }

    &:focus {
      outline: 0;
    }
  }
  b,
  strong {
    font-weight: 700;
  }
  a {
    text-decoration: none;
    &:active,
    &:visited {
      color: initial;
    }
  }
  h1,h2,h3,h4 {
    margin: 0;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`

export default GlobalStyles
