import classNames from 'classnames'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Button from 'components/Atoms/Button'

const Cookie = require('universal-cookie')

const EdcButton = styled(Button)`
  align-items: center;
  justify-content: center;
  display: flex;
`

const EdcIcon = styled.img`
  margin-right: 10px;
  height: 20px;
  width: 20px;
  font-size: ${props => props.theme.font.size.mobile.h4};
`

export const redirectToEventsLogin = () => {
  const params = new URLSearchParams(window.location.search)

  params.delete('forcelogin')
  const finalUrl = `${window.location.origin}${window.location.pathname}?${params}`
  const state = btoa(
    JSON.stringify({
      redirect_uri: finalUrl,
    })
  )
  const nonce = (Math.random() + 1).toString(36).substring(3)

  const clientId = process.env.NEXT_PUBLIC_EDC_CLIENT_ID
  const redirectUri = process.env.NEXT_PUBLIC_EDC_REDIRECT_URI
  const authUrl = process.env.NEXT_PUBLIC_EDC_AUTH_URL
  const authScope =
    'email VIEW_ORGANIZATION_DETAILS refreshToken openid VIEW_EVENT'

  const cookies = new Cookie()

  cookies.set('edcsource', 'Evensi', { domain: 'events.com' })

  window.location.href = `${authUrl}authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${authScope}&response_type=code&response_mode=fragment&nonce=${nonce}&state=${state}&intent=promote`
}

export const EdcLoginButton = ({ text = 'Login with events.com' }) => (
  <div>
    <EdcButton
      onClick={redirectToEventsLogin}
      className={classNames('edc', 'full')}>
      <EdcIcon src={'/static/images/events-icon.png'} />
      {text}
    </EdcButton>
  </div>
)

EdcLoginButton.propTypes = {
  text: PropTypes.string,
}
