import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useState } from 'react'
import styled from 'styled-components'

import ApplePayButton from 'components/Atoms/ApplePayButton'
import Button from 'components/Atoms/Button'
import { EdcLoginButton } from 'components/Atoms/EdcLoginButton'
import FacebookButton from 'components/Atoms/FacebookButton'
import Label from 'components/Atoms/Label'
import PageTitle from 'components/Atoms/PageTitle'
import Text from 'components/Atoms/Text'
import TextDivisor from 'components/Atoms/TextDivisor'
import { SignInForm } from 'components/Molecules/SignIn'
import { isCitifydApp } from 'helpers/application'
import isLightMode from 'helpers/isLightMode'
import { getThemeName } from 'models/application'

const ModalSection = styled.div`
  margin: 20px 0;

  .phoneNumber {
    margin: 25px 0 15px;
    font-size: ${props => props.theme.font.size.mobile.small};

    @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
      font-size: ${props => props.theme.font.size.desktop.regular};
    }
  }

  .form {
    margin: 0;
    display: flex;
    justify-content: space-evenly;
  }

  .signUpLink {
    font-size: ${props => props.theme.font.size.mobile.medium};
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    margin-left: 35px;
    margin-right: 35px;

    .signUpLink {
      font-size: ${props => props.theme.font.size.desktop.h4};
    }
  }
`

const LoginButtons = styled.div`
  button + button {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`

const Links = styled.div`
  text-align: center;
`

const Link = styled(Text)`
  display: inline-block;
  font-size: ${props => props.theme.font.size.mobile.h4};
  cursor: pointer;
`

const Divisor = styled.div`
  margin-top: 20px;
  border-top: 1px solid ${props => props.theme.palette.gray}80;
  display: none;

  &.title {
    margin-top: 0;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    display: block;
  }
`

const SignIn = ({
  application,
  country,
  getGuestSession,
  hasGuestLogin,
  hasGuestLoginButton,
  isAppleLogin,
  isFacebookLogin,
  onForgotPasswordLink,
  onSetGuestSession,
  onSignUpLink,
  phoneNumber,
  preventClose,
  receiptFlow,
  redirectTo,
  setPhoneNumber,
  t,
}) => {
  const [hasSocialButtons, setSocialButtons] = useState(true)
  const [hasSignUpLink, setSignUpLink] = useState(true)

  const handleClick = () => onSetGuestSession()

  const isGuestAvailable = !getGuestSession() && hasGuestLogin
  const showGuestLoginButton = !getGuestSession() && hasGuestLoginButton

  return (
    <>
      <PageTitle
        modal
        noBorder
        shadow
        showBackButton={!preventClose}
        title={
          hasSocialButtons && isCitifydApp(application)
            ? t('signIn.hasSocialButtonsTitle')
            : t('signIn.title')
        }
      />
      <Divisor />
      {!isAppleLogin && !isFacebookLogin && (
        <ModalSection className="main">
          {hasSocialButtons && (
            <>
              <LoginButtons>
                {showGuestLoginButton && (
                  <>
                    <Button
                      className={classNames('full', 'uppercase', {
                        grayDark: isLightMode(application),
                        contrastText: !isLightMode(application),
                      })}
                      onClick={handleClick}>
                      {t('common.button.continueAsGuest')}
                    </Button>
                    <TextDivisor text={t('signIn.or')} />
                  </>
                )}
                {isCitifydApp(application) && (
                  <>
                    <ApplePayButton
                      skipLoginFlow={Boolean(receiptFlow)}
                      showSupportMessage={Boolean(receiptFlow)}
                      callbackRedirection={redirectTo?.as}
                      className="full">
                      {t('common.button.continueWithApple')}
                    </ApplePayButton>

                    <FacebookButton
                      showSupportMessage={Boolean(receiptFlow)}
                      className="full">
                      {t('common.button.continueWithFacebook')}
                    </FacebookButton>

                    <EdcLoginButton
                      text={t('common.button.continueWithEvents')}
                    />
                  </>
                )}
              </LoginButtons>
              {(isCitifydApp(application) || showGuestLoginButton) && (
                <TextDivisor
                  className="phoneNumber"
                  text={t('signIn.form.phoneNumber.label')}
                />
              )}
              {!isCitifydApp(application) && !showGuestLoginButton && (
                <Label>{t('signIn.form.phoneNumber.label')}</Label>
              )}
            </>
          )}
          <SignInForm
            application={application}
            className="form"
            country={country}
            onForgotPasswordLink={onForgotPasswordLink}
            redirectTo={redirectTo}
            setPhoneNumber={setPhoneNumber}
            phoneNumber={phoneNumber}
            setSignUpLink={setSignUpLink}
            setSocialButtons={setSocialButtons}
            isGuestAvailable={isGuestAvailable}
          />
        </ModalSection>
      )}
      {hasSignUpLink &&
        !receiptFlow &&
        getThemeName(application) !== 'Blazers' && (
          <>
            <Divisor />
            <ModalSection>
              <Links>
                <Link
                  className="signUpLink secondary bold center"
                  onClick={() => onSignUpLink()}>
                  <a>{t('signIn.signUpLink')}</a>
                </Link>
              </Links>
            </ModalSection>
          </>
        )}
    </>
  )
}

SignIn.propTypes = {
  application: PropTypes.string,
  country: PropTypes.string,
  getGuestSession: PropTypes.func,
  hasGuestLogin: PropTypes.bool,
  hasGuestLoginButton: PropTypes.bool,
  isAppleLogin: PropTypes.bool,
  isFacebookLogin: PropTypes.bool,
  onForgotPasswordLink: PropTypes.func,
  onSetGuestSession: PropTypes.func,
  onSignUpLink: PropTypes.func,
  phoneNumber: PropTypes.string,
  preventClose: PropTypes.bool,
  receiptFlow: PropTypes.object,
  redirectTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setPhoneNumber: PropTypes.func,
  t: PropTypes.func,
}

export default SignIn
