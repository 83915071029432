import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { createContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { attemptLogin } from 'redux-web/utils/authentication/actions'

export const EdcContext = createContext()

export const EdcProvider = ({ children }) => {
  const showErrorMessage = false
  const showSupportMessage = false

  const dispatch = useDispatch()
  const router = useRouter()

  useEffect(() => {
    const { eventsToken } = router.query

    if (eventsToken) {
      dispatch(
        attemptLogin({
          data: { eventsToken: eventsToken },
          showErrorMessage: showErrorMessage,
          showSupportMessage: showSupportMessage,
        })
      )
    }
  }, [router.query])

  return <EdcContext.Provider value={{}}>{children}</EdcContext.Provider>
}

EdcProvider.propTypes = { children: PropTypes.node }
